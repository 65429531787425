<template>
    <div class="helpFaq">
        <div class="helpFaq__topics">
            <UiButton
                v-for="topic in topics"
                :key="topic.id"
                :class="[topic.id === activeTopic && 'active']"
                size="sm"
                type="secondary"
                :text="topic.label"
                @click="activeTopic = topic.id"
            />
        </div>

        <div class="helpFaq__list">
            <div
                class="helpFaq__element"
                :class="activeListElement === el.id && 'is-active'"
                v-for="el in list"
                :key="el.id"
            >
                <div class="helpFaq__head" @click="handleElementSelect(el.id)">
                    <div class="helpFaq__title">{{ el.title }}</div>
                    <div class="helpFaq__toggle">
                        <svg-vue icon="plus" />
                    </div>
                </div>
                <div class="helpFaq__content" v-if="activeListElement === el.id">{{ el.content }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"

export default {
    data() {
        return {
            activeTopic: 1,
            activeListElement: null,
        }
    },
    computed: {
        topics() {
            return this.faq.map((x) => ({
                id: x.id,
                label: x.title,
            }))
        },
        list() {
            const topic = this.faq.find((x) => x.id === this.activeTopic)

            if (topic && topic.category.length) {
                return topic.category.map((x, idx) => ({
                    ...x,
                    id: idx,
                }))
            }
            return []
        },
        ...mapGetters("ticket", ["faq"]),
    },
    methods: {
        handleElementSelect(id) {
            this.activeListElement = this.activeListElement === id ? null : id
        },
    },
}
</script>

<style lang="scss" scoped>
.helpFaq {
    &__topics {
        margin-top: 15px;
        margin-bottom: -10px;
        display: flex;
        flex-wrap: wrap;
        button {
            margin-right: 10px;
            margin-bottom: 10px;
            padding-left: 15px;
            padding-right: 15px;
            border-color: var(--color-silver);
            color: var(--color-silver);
            &.active {
                border-color: var(--color-yellow);
                color: var(--color-yellow);
            }
            &:hover {
                color: var(--color-cinder);
            }
        }
    }
    &__list {
        margin-top: 10px;
    }
    &__element {
        border-bottom: 1px solid rgba(white, 0.2);
        &:last-child {
            border-bottom-color: transparent;
        }
        &.is-active {
            .helpFaq__toggle {
                transform: rotate(135deg);
            }
        }
    }
    &__head {
        padding: 10px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
            .helpFaq__title {
                opacity: 0.8;
            }
        }
    }
    &__title {
        padding-right: 16px;
        font-weight: 600;
        font-size: 16px;
        line-height: calc(24 / 16);
        transition: opacity 0.25s ease-in-out;
    }
    &__toggle {
        margin-left: auto;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--color-charade);
        transition: transform 0.25s ease-in-out;
        will-change: transform;
    }
    &__content {
        font-weight: 400;
        font-size: 16px;
        line-height: calc(24 / 16);
        margin-bottom: 26px;
    }
}

@media (max-width: 767px) {
    .helpFaq {
        &__title {
            font-size: 14px;
        }
        &__content {
            font-size: 14px;
        }
    }
}
</style>
