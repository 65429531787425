<template>
    <div class="help">
        <div class="help__section">
            <div class="help__title">{{ $t("help.welcome.title") }}</div>
            <div class="help__box">
                <div class="help__box-wrapper">
                    <div class="help__box-content">
                        <div class="help__subtitle">{{ $t("help.welcome.subtitle") }}</div>
                        <div class="help__main-text">{{ $t("help.welcome.description") }}</div>
                        <div class="help__cta">
                            <router-link :to="{ name: 'helpcreate' }">
                                <UiButton :text="$t('help.welcome.create')" />
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="help__text">
                <i18n path="help.welcome.midText">
                    <template #newline><br /></template>
                </i18n>
            </div>
        </div>

        <div class="help__section">
            <div class="help__box">
                <div class="help__box-wrapper">
                    <div class="help__box-content">
                        <div class="help__subtitle">{{ $t("help.tickets.title") }}</div>

                        <HelpTickets class="help__list" />
                    </div>
                </div>
            </div>
        </div>

        <div class="help__section">
            <div class="help__box">
                <div class="help__box-wrapper">
                    <div class="help__box-content">
                        <div class="help__subtitle">{{ $t("help.faq.title") }}</div>

                        <HelpFaq />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"
import HelpTickets from "@/components/Help/Tickets.vue"
import HelpFaq from "@/components/Help/Faq.vue"

export default {
    components: { HelpTickets, HelpFaq },
}
</script>
